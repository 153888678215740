type PlanPriceInfo =
  | "FREE"
  | {
    price: number;
    duration: string;
    planName: string;
    planIcon: any;
  };
export interface PlanFeatures {
  featureName: string;
  avail: boolean;
}

export interface PlanCardPops {
  planPriceInfo?: PlanPriceInfo;
  features: PlanFeatures[];
  row?: boolean;
  onClick: VoidFunction;
  showShimmer?: boolean;
  link: string
  isSameSite?: boolean
}

enum InstamojoLinksTest {
  PLAN_100 = "https://test.instamojo.com/@prasantabarman06/l642a3a6e3fa24fe898c1ae1aef03f0fe",
  PLAN_200 = "https://www.instamojo.com/@GetMutualTransfer/l7f655397375643abb0597387eb5c517e/",
  PLAN_500 = "https://www.instamojo.com/@GetMutualTransfer/l47a07ee17ba94fb3880f3abcb22e22af/"

}

enum InstamojoLinksProd {
  PLAN_100 = "https://www.instamojo.com/@GetMutualTransfer/le43ab9cfa572417eaa4af0fbf8f01f03/",
  PLAN_200 = "https://www.instamojo.com/@GetMutualTransfer/l7f655397375643abb0597387eb5c517e/",
  PLAN_500 = "https://www.instamojo.com/@GetMutualTransfer/l47a07ee17ba94fb3880f3abcb22e22af/"
}

enum PayULinksProd {
  PLAN_100 = "https://pmny.in/yr55CmoDr4bW",
  PLAN_200 = "https://pmny.in/MITPHRQAOdqS",
  PLAN_500 = "https://pmny.in/dIdqWAsE57WJ"
}

const InstamojoLinks = PayULinksProd
//#region plan info

const basicPlanIcon = "/assets/svg/plans/basic.svg";
const standardPlanIcon = "/assets/svg/plans/standard.svg";
const masterPlanIcon = "/assets/svg/plans/master.svg";

const freeFeatures = [
  "Notification if any matches happen",
  "Limited List Of Registered users",
];

const features = [
  "Full List Of Registered users",
  "Partners Contact Details",
  "User Data from Outside Sources",
  // "Ad Free Experience In Website And Mobile App",
];

export const freePlan: PlanCardPops = {
  planPriceInfo: "FREE",
  features: [
    { avail: true, featureName: freeFeatures[0] },
    { avail: true, featureName: freeFeatures[1] },
    {
      avail: false,
      featureName: features[0],
    },
    {
      avail: false,
      featureName: features[1],
    },
    {
      avail: false,
      featureName: features[2],
    },
    // {
    //   avail: false,
    //   featureName: features[3],
    // },
  ],
  onClick: undefined,
  link: "/register",
  isSameSite: true
};

export const standardPlan: PlanCardPops = {
  planPriceInfo: {
    duration: "/6 months",
    price: 200,
    planName: "Standard",
    planIcon: standardPlanIcon,
  },
  features: [
    { avail: true, featureName: freeFeatures[0] },
    {
      avail: true,
      featureName: features[0],
    },
    {
      avail: true,
      featureName: features[1],
    },
    {
      avail: true,
      featureName: features[2],
    },
    // {
    //   avail: true,
    //   featureName: features[3],
    // },
  ],
  onClick: undefined,
  link: InstamojoLinks.PLAN_200
};

export const masterPlan: PlanCardPops = {
  planPriceInfo: {
    duration: "/2 Years",
    price: 500,
    planName: "Master",
    planIcon: masterPlanIcon,
  },
  features: [
    { avail: true, featureName: freeFeatures[0] },
    {
      avail: true,
      featureName: features[0],
    },
    {
      avail: true,
      featureName: features[1],
    },
    {
      avail: true,
      featureName: features[2],
    },
    // {
    //   avail: true,
    //   featureName: features[3],
    // },
  ],
  onClick: undefined,
  link: InstamojoLinks.PLAN_500
};

export const basicPlan: PlanCardPops = {
  planPriceInfo: {
    duration: "/month",
    price: 100,
    planName: "Basic",
    planIcon: basicPlanIcon,
  },
  features: [
    { avail: true, featureName: freeFeatures[0] },
    {
      avail: true,
      featureName: features[0],
    },
    {
      avail: true,
      featureName: features[1],
    },
    {
      avail: false,
      featureName: features[2],
    },
    // {
    //   avail: false,
    //   featureName: features[3],
    // },
  ],
  onClick: undefined,
  link: InstamojoLinks.PLAN_100
};
//#endregion
// import {
//   basicPlan,
//   freePlan,
//   masterPlan,
//   standardPlan,
// } from "../pages/PlansPage/PlansPage";

export class Urls {
  static readonly HOME_PAGE_URL = "/";
  static readonly PLANS_URL = "/plans";
  //#region account related
  static readonly LOGIN_URL = "/login";
  static readonly REGISTER_URL = "/register";
  static readonly MY_ACCOUNT_URL = "/account";
  static readonly CHANGE_PASSWORD_URL = "/account/change-password";
  static readonly EDIT_INFORMATION_URL = "/account/edit-information";
  static readonly MY_PLAN_URL = "/account/my-plan";
  static readonly FORGOT_PASSWORD_URL = "/account/forgot-password";
  static readonly ALL_MATCHES = "/account/all-matches";
  //#endregion account related

  //#region admin
  static readonly ADMIN_DASHBOARD = "/admin/dashboard";
  static readonly ADD_A_PAYMENT = "/admin/add-a-payment";
  //#endregion admin

  //#region register for categories
  static readonly HIGH_SCHOOL_REGISTER_URL = "/register/high-school";
  static readonly PRIMARY_SCHOOL_REGISTER_URL = "/register/primary-school";
  static readonly NON_TEACHING_STAFF_REGISTER_URL =
    "/register/non-teaching-staff";
  static readonly POSTAL_STAFF_REGISTER_URL = "/register/postal-staff";
  static readonly RAILWAY_STAFF_REGISTER_URL = "/register/railway-staff";
  static readonly COLLAGE_STAFF_REGISTER_URL = "/register/college-staff";
  //#endregion register for categories

  //#region categories urls
  static readonly HIGH_SCHOOL_URL = "/mutual-transfer/high-school";
  static readonly PRIMARY_SCHOOL_URL = "/mutual-transfer/primary-school";
  static readonly NON_TEACHING_STAFF_URL =
    "/mutual-transfer/non-teaching-staff";
  static readonly POSTAL_STAFF_URL = "/mutual-transfer/postal-staff";
  static readonly RAILWAY_STAFF_URL = "/mutual-transfer/railway-staff";
  static readonly COLLAGE_STAFF_URL = "/mutual-transfer/college-staff";
  //#endregion categories urls

  //#region external urls
  static readonly GOOGLE_PLAY_URL =
    "https://play.google.com/store/apps/details?id=in.getmutualtransfer";
  //#endregion external urls

  //#region footer urls
  static readonly ABOUT_US_URL = "/about-us";
  static readonly TERMS_AND_CONDITION_URL = "/terms-and-condition";
  static readonly PRIVACY_POLICY_URL = "/privacy-policy";
  static readonly REFUNDS_CANCELLATIONS_POLICY_URL = "/refund-policy";
  static readonly PRICING_URL = "/plans";
  //#endregion footer urls

  //#region registration urls
  static readonly CHOOSE_CATEGORY_FOR_REGISTER_URL =
    "/register?choose_category";
  //#endregion registration urls
  //#region social media
  static readonly TWITTER_URL = "https://twitter.com/softlake_org";
  static readonly FACEBOOK_URL = "https://www.facebook.com/SoftlakeOrg";
  static readonly LINKED_IN_URL = "https://www.linkedin.com/company/softlake/";
  static readonly INSTAGRAM_URL = "https://www.instagram.com/softlake_org/";
  //#endregion social media
}

export enum AllPlans {
  FREE = "PLAN_FREE",
  PLAN_100 = "PLAN_100",
  PLAN_200 = "PLAN_200",
  PLAN_500 = "PLAN_500",
}

export interface PlanConfigDetails {
  planName: AllPlans;
  planExpiry: Date;
  planStart: Date;
  isOutSideEnable: boolean;
  showContact: boolean;
  duration: string;
}

export const getPlanCardDataFromName = (planName: AllPlans): PlanCardPops => {
  switch (planName) {
    case AllPlans.FREE:
      return freePlan;
    case AllPlans.PLAN_100:
      return basicPlan;
    case AllPlans.PLAN_200:
      return standardPlan;
    case AllPlans.PLAN_500:
      return masterPlan;
    default:
      return {
        features: [],
        onClick: () => null,
        planPriceInfo: "FREE",
        row: true,
        link: ""
      };
  }
};
export const getPlanGoodName = (planName: AllPlans): string => {
  switch (planName) {
    case AllPlans.PLAN_100:
      return "Basic";
    case AllPlans.PLAN_200:
      return "Standard";
    case AllPlans.PLAN_500:
      return "Master";
    case AllPlans.FREE:
    default:
      return "Free";

  }
};

export const LOADING_LIMIT_IN_SEARCH_PAGE = 10;

export const shouldLoadMoreDataInSearchPage = (
  isLoggedIn,
  _searchResult = []
) => {
  console.log("shouldLoadMoreDataInSearchPage -> ", isLoggedIn,
    _searchResult);

  if (_searchResult.length === 0) return false;

  const dbLimitHasNotReached =
    _searchResult.length === LOADING_LIMIT_IN_SEARCH_PAGE;

  console.log("dbLimitHasNotReached", dbLimitHasNotReached);

  if (dbLimitHasNotReached) return true;
  // if (isLoggedIn) {
  //   if (dbLimitHasNotReached) {
  //     return true;
  //   } else console.log("dbLimitHasNotReached", dbLimitHasNotReached);
  // } else {
  //   console.log("isLoggedIn", isLoggedIn);
  // }
  // console.log("return false");

  return false;
};

export type WithLastDoc<T> = T & { doc: any };
