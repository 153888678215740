import firebase from "firebase";
// import firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/auth";

//#region firebase init
const firebaseConfig = {
  apiKey: "AIzaSyD5dDRFk2zrqM9sm58HSY4c-8JGxg5Jftg",
  authDomain: "getmutualtrasnfer.firebaseapp.com",
  databaseURL:
    "https://getmutualtrasnfer-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "getmutualtrasnfer",
  storageBucket: "getmutualtrasnfer.appspot.com",
  messagingSenderId: "521396582029",
  appId: "1:521396582029:web:cd10d51573426234e9d4c4",
  measurementId: "G-CFHYK31JZP",
};

// Firebase previously initialized using
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  console.log("firebase is already initialized skipping ...");
}
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const database = firebase.database();
export const functions = firebase.functions();

// const storage = firebase.storage();
// storage.useEmulator("localhost", 9199);

const disableWarnings = {
  disableWarnings: true,
};

const getHost = () => {
  const host = process.env["NEXT_PUBLIC_EMULATOR_HOST"] || "localhost";
  return host;
};

console.log(getHost());
console.log(
  "location" in globalThis,
  globalThis?.location?.hostname,
  "localhost",
  process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR,
  process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR === "1" ||
    process.env.NODE_ENV === "development",
  "useEmulator",
  "location" in globalThis &&
    location.hostname === "localhost" &&
    (process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR === "1" ||
      process.env.NODE_ENV === "development")
);

if (
  "location" in globalThis &&
  location.hostname === "localhost" &&
  (process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR === "1" ||
    process.env.NODE_ENV === "development")
) {
  console.log("location", getHost());

  // this is the bug of firebase package
  // firebase package doesn't provide the right typings
  // @see https://github.com/firebase/firebase-js-sdk/issues/4223
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  auth.useEmulator(
    `http://${getHost()}:9099`,
    // @ts-ignore
    disableWarnings
  );
  // firebase.firestore.setLogLevel("debug");
  firestore.useEmulator(`${getHost()}`, 8080);
  firebase.functions().useEmulator(`${getHost()}`, 5001);
  database.useEmulator(getHost(), 9000);
  firestore.settings({ experimentalForceLongPolling: true, merge: true });
  functions.useEmulator(getHost(), 5001);
}

//#endregion
