export const GA_TRACKING_ID = "G-CFHYK31JZP";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL): void => {
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    });
};

type GTagEvent = {
    category: string;
    action: string;
    label: string;
    value?: number;
    non_interaction?: boolean
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value, non_interaction }: GTagEvent): void => {
    if (!window.gtag) {
        console.log("no gtag found", { action, category, label, value, non_interaction });
        return
    }
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        ...(value && ({ value })),
        ...(non_interaction && ({ non_interaction }))
    });
};