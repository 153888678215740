export var PERSONAL_INFO_COLLECTION_NAME = "personal_info";
export var SERVICE_INFO_COLLECTION_NAME = "service_info";
export var HIGH_SCHOOL_PERSONAL_INFO = "high_school_personal_info";
export var HIGH_SCHOOL_SERVICE_INFO = "high_school_service_info";
export var PRIMARY_SCHOOL_PERSONAL_INFO = "primary_school_personal_info";
export var PRIMARY_SCHOOL_SERVICE_INFO = "primary_school_service_info";
export var NON_TEACHING_STAFF_PERSONAL_INFO = "non_teaching_staff_personal_info";
export var NON_TEACHING_STAFF_SERVICE_INFO = "non_teaching_staff_service_info";
export var POSTAL_STAFF_PERSONAL_INFO = "postal_staff_personal_info";
export var POSTAL_STAFF_SERVICE_INFO = "postal_staff_service_info";
export var RAILWAY_STAFF_PERSONAL_INFO = "railway_staff_personal_info";
export var RAILWAY_STAFF_SERVICE_INFO = "railway_staff_service_info";
export var COLLEGE_STAFF_PERSONAL_INFO = "college_staff_personal_info";
export var COLLEGE_STAFF_SERVICE_INFO = "college_staff_service_info";
export var MUTUAL_TRANSFER_CATEGORIES;

(function (MUTUAL_TRANSFER_CATEGORIES) {
  MUTUAL_TRANSFER_CATEGORIES["HIGH_SCHOOL"] = "high_school";
  MUTUAL_TRANSFER_CATEGORIES["PRIMARY_SCHOOL"] = "primary_school";
  MUTUAL_TRANSFER_CATEGORIES["NON_TEACHING_STAFF"] = "non_teaching_staff";
  MUTUAL_TRANSFER_CATEGORIES["RAILWAY_STAFF"] = "railway_staff";
  MUTUAL_TRANSFER_CATEGORIES["POSTAL_STAFF"] = "postal_staff";
  MUTUAL_TRANSFER_CATEGORIES["COLLAGE_STAFF"] = "collage_staff";
})(MUTUAL_TRANSFER_CATEGORIES || (MUTUAL_TRANSFER_CATEGORIES = {}));