import * as ga from '../../lib/ga'
import firebase from 'firebase'
import { FieldError } from "react-hook-form";
import { MyAccountSideBarActions } from "../components/molecules/MyAccountSideBar/MyAccountSideBar";
import { MUTUAL_TRANSFER_CATEGORIES } from '../db/types/common';
import { Urls } from "./constant";

export const handelSideBarClick = (action, router) => {
  switch (action) {
    case MyAccountSideBarActions.LOG_OUT:
      router.push(Urls.HOME_PAGE_URL);
      break;
  }
};

export const convertFieldErrorToErrorMsg = (
  name: string,
  error?: FieldError
): string => {
  if (error?.message) return error.message;
  if (error?.type === "required") return `Please Enter ${name}`;
  if (error?.type === "pattern") return `Please Enter a valid ${name}`;
  return ``;
};

export function objectEquals(x, y) {
  "use strict";

  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  var p = Object.keys(x);
  return (
    Object.keys(y).every(function (i) {
      return p.indexOf(i) !== -1;
    }) &&
    p.every(function (i) {
      return objectEquals(x[i], y[i]);
    })
  );
}

export const arr_set_value = (entries: string[] = []): string[] => {
  let new_arr = [];
  for (const entry of entries)
    if (entry && !new_arr.includes(entry)) new_arr.push(entry);

  return new_arr;
};

export function insertIf(condition, ...elements) {
  console.log(" insertIf", condition, elements);

  // (A)
  return condition ? elements : [];
}

export function getNoResultMsg(searchResult: any[], url: string): string {
  const noResultMsg =
    searchResult.length === 0
      ? url.includes("search_result")
        ? "Seems there is no match with your search criteria"
        : "Seems there is no result for this category"
      : "";

  return noResultMsg
}

declare global {
  interface Window {
    Android?: {
      share?: (title: string, body: string) => void;
      reload?: VoidFunction
    };
    dataLayer?: any[]
  }
}

export const isAndroidApp = () => navigator.userAgent.includes(" wv") && ("Android" in window);

const isWebViewShareAvailable = () => ("Android" in window && Object.keys(window.Android).includes("share"))
export const isShareAvailable = () => ("share" in navigator) || isWebViewShareAvailable()
export const shareWebsite = () => {
  const shareData = {
    title: 'Get Mutual Transfer',
    text: "Get Mutual Transfer - India's largest network to find mutual partner for Govt employees.",
    url: 'https://getmutualtransfer.in',
  }
  if (isShareAvailable()) {
    if (navigator.share)
      navigator.share(shareData)
    else if (isWebViewShareAvailable()) {
      // @ts-ignore
      window?.Android?.share(shareData.title, shareData.text + "\n" + shareData.url)
    }
  } else {
    console.log("Share api is not available");

  }
}
type WithId<T> = T & {
  id: string;
};

export function removeMyProfile<T>(docs: WithId<T>[], user?: firebase.User): T[] {
  // console.log("removeMyProfile", docs, user);
  return docs.filter((e) => e.id !== user?.uid);
}

// 
const pad = (num: number): string => (num < 10 ? "0" + num : "" + num);

export const formatDate = (date: Date): string =>
  `${pad(date.getDate())}-${pad(date.getMonth() + 1)}-${date.getFullYear()}`;

export const convertCategoryKeyToGood = (key: MUTUAL_TRANSFER_CATEGORIES): string => {
  switch (key) {
    case MUTUAL_TRANSFER_CATEGORIES.HIGH_SCHOOL:
      return "High School"
    case MUTUAL_TRANSFER_CATEGORIES.PRIMARY_SCHOOL:
      return "Primary School"
    case MUTUAL_TRANSFER_CATEGORIES.COLLAGE_STAFF.replaceAll("collage", "college"):
      return "Collage/University Staff"
    case MUTUAL_TRANSFER_CATEGORIES.POSTAL_STAFF:
      return "Postal Staff"
    case MUTUAL_TRANSFER_CATEGORIES.RAILWAY_STAFF:
      return "Railway Staff"
    case MUTUAL_TRANSFER_CATEGORIES.NON_TEACHING_STAFF:
      return "Non Teaching Staff"
    default:
      return ""
  }
}


export const trackRegistration = (category: MUTUAL_TRANSFER_CATEGORIES) => {
  ga.event({
    category: "sign_up",
    action: "Sign up Successful",
    label: category
  })
}

export const getMTCategoryFormUrl = (): MUTUAL_TRANSFER_CATEGORIES | "My Account" => {
  const url = window.location.href.replaceAll("-", "_");
  if (url.includes(MUTUAL_TRANSFER_CATEGORIES.HIGH_SCHOOL)) return MUTUAL_TRANSFER_CATEGORIES.HIGH_SCHOOL;
  if (url.includes(MUTUAL_TRANSFER_CATEGORIES.PRIMARY_SCHOOL)) return MUTUAL_TRANSFER_CATEGORIES.PRIMARY_SCHOOL;
  if (url.includes(MUTUAL_TRANSFER_CATEGORIES.COLLAGE_STAFF)) return MUTUAL_TRANSFER_CATEGORIES.COLLAGE_STAFF;
  if (url.includes(MUTUAL_TRANSFER_CATEGORIES.NON_TEACHING_STAFF)) return MUTUAL_TRANSFER_CATEGORIES.NON_TEACHING_STAFF;
  if (url.includes(MUTUAL_TRANSFER_CATEGORIES.POSTAL_STAFF)) return MUTUAL_TRANSFER_CATEGORIES.POSTAL_STAFF;
  if (url.includes(MUTUAL_TRANSFER_CATEGORIES.RAILWAY_STAFF)) return MUTUAL_TRANSFER_CATEGORIES.RAILWAY_STAFF;
  if (url.includes("account")) return "My Account"
}





function setCookie(name: string, value: string, days?: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const DISABLE_ANALYTICS = "DISABLE_ANALYTICS"


export const enableGa = () => {
  console.log("[*] enableGa");
  window[`ga-disable-${ga.GA_TRACKING_ID}`] = false;
  setCookie(DISABLE_ANALYTICS, "", -10)

}

export const disableGa = () => {
  console.log("[*] disableGa");
  window[`ga-disable-${ga.GA_TRACKING_ID}`] = true;
  setCookie(DISABLE_ANALYTICS, "true")

}
if (typeof window !== "undefined") {
  window["setCookie"] = setCookie
  window["getCookie"] = getCookie
  window["enableGa"] = enableGa
  window["disableGa"] = disableGa
}
