import React from "react";
import { Auth, User } from "./auth";
import { database, firestore } from "./../util/firebase";
import { disableGa } from "../util";
import firebase from "firebase";

const auth = new Auth(); // singleton

const redirectKey = "sign_in_redirect";

interface AuthContextProps {
  auth: Auth;
  initializing: boolean;
  user: User | null;
  error: {
    message: string;
  };
  setRedirect: (redirect: string) => void;
  getRedirect: () => string | null;
  clearRedirect: () => void;
  logout: () => Promise<void>;
  isContactShowCapable: () => boolean;
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined
);

AuthContext.displayName = "AuthContext";

function setRedirect(redirect: string) {
  window.sessionStorage.setItem(redirectKey, redirect);
}

function getRedirect(): string | null {
  return window.sessionStorage.getItem(redirectKey);
}

function clearRedirect() {
  return window.sessionStorage.removeItem(redirectKey);
}
export function useAuth(): Partial<AuthContextProps> {
  const auth = React.useContext(AuthContext);

  if (!auth) {
    // throw new Error("useAuth must be used within AuthProvider");
    console.error("useAuth must be used within AuthProvider");
    return { user: null };
  }

  return auth;
}

interface State {
  user: User;
  error: { message: string } | null;
  initializing: boolean;
  claims: {
    [key: string]: any;
  } | null;
}
export class AuthProvider extends React.Component<{}, State> {
  state: State = {
    error: null,
    initializing: true,
    user: null,
    claims: null,
  };
  isContactShowCapable = (): boolean => {
    const planConfig = this.state?.claims?.planConfig;
    const showContact = planConfig?.showContact;
    // console.log("isContactShowCapable",planConfig, showContact);

    return !!showContact;
  };

  componentDidMount() {
    console.log("cdm", window);
    let callback = null;

    // @ts-ignore
    window.firebase = firebase;

    auth.onAuthStateChanged((user: User, error) => {
      console.log("auth state changed ", user);
      if (user) {
        auth.user
          .getIdTokenResult()
          .then((idTokenResult) => {
            console.log("idTokenResult", idTokenResult.claims);
            this.setState({ claims: idTokenResult.claims });
          })
          .catch((error) => {
            console.log(error);
          });

        this.setState({ user, error: null, initializing: false });
        console.log("metadata/" + user.uid + "/refreshTime");
        const metadataRef = database.ref(
          "metadata/" + user.uid + "/refreshTime"
        );
        console.log();

        callback = (snapshot) => {
          console.log("snapshot", snapshot);
          // Force refresh to pick up the latest custom claims changes.
          // Note this is always triggered on first call. Further optimization could be
          // added to avoid the initial trigger when the token is issued and already contains
          // the latest claims.
          user.getIdToken(true);
          auth.user
            .getIdTokenResult()
            .then((idTokenResult) => {
              console.log("idTokenResult", idTokenResult.claims);
              this.setState({ claims: idTokenResult.claims });
            })
            .catch((error) => {
              console.log(error);
            });

          if (user && user.email && user.email.includes("test@gmail.com")) {
            disableGa();
          }
        };
        // Subscribe new listener to changes on that node.

        const res = metadataRef.on("value", (val) => {
          callback(val);
          setTimeout(() => {
            callback(val);
          }, 1.5e3);
        });
        console.log("register listner", res);
      } else {
        this.setState({ user: null, error, initializing: false });
      }
    });
  }

  logout = async () => {
    await auth.signOut();
  };

  render() {
    const { logout, isContactShowCapable } = this;
    const { error, initializing, user } = this.state;
    const value: AuthContextProps = {
      user,
      error,
      auth,
      initializing,
      setRedirect,
      getRedirect,
      clearRedirect,
      logout,
      isContactShowCapable,
    };
    const { children } = this.props;
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }
}

// export function AuthProvider({ children }: { children: JSX.Element }) {
//   const [user, setUser] = useState<User | null>(null);
//   const [error, setError] = useState<{ message: string } | null>(null);
//   const [initializing, setInitializing] = useState(true);

//   /*
//     NOTICE: this is not production ready code!
//     just a quick demo of resolving the initial user
//   */
//   useEffect(() => {
//     auth.onAuthStateChanged((user: User, error) => {
//       console.log("auth state changed ", user);
//       if (user) {
//         setUser(user);
//         setError(null);
//       } else {
//         setUser(null);
//         if (error) {
//           setError(error);
//         }
//       }
//       setInitializing(false);
//     });
//   }, []);

//   const value = {
//     user,
//     error,
//     auth,
//     initializing,
//     setRedirect,
//     getRedirect,
//     clearRedirect,
//   };

//   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// }
